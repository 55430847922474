module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","redirect":false,"languages":["de"],"generateDefaultLanguagePage":false,"defaultLanguage":"de","fallbackLanguage":"en","siteUrl":"https://duotec.de","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/:lang([a-z]{2})?/:slug","getLanguageFromPath":true},{"matchPath":"/:lang([a-z]{2})?/:division/:slug","getLanguageFromPath":true}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"duotec","short_name":"duotec","description":"Zukunftsweisende Sensor- und Konnektivitätsplattformen","icon":"resources/images/icon.png","lang":"de","start_url":"/","background_color":"#091121","theme_color":"#256fc9","display":"minimal-ui","localize":[],"icons":[{"src":"/appicons/duotec-48x48.png","sizes":"48x48","type":"image/png"},{"src":"/appicons/duotec-72x72.png","sizes":"72x72","type":"image/png"},{"src":"/appicons/duotec-96x96.png","sizes":"96x96","type":"image/png"},{"src":"/appicons/duotec-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/appicons/duotec-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/appicons/duotec-256x256.png","sizes":"256x256","type":"image/png"},{"src":"/appicons/duotec-384x384.png","sizes":"384x384","type":"image/png"},{"src":"/appicons/duotec-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"17dcb70dccddc0af1ab1668e11722d63"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

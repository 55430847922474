module.exports = {
  languages: [
    /*
    {
      code: 'en',
      name: 'English',
      nativeName: 'English',
      short: 'EN',
      manifest: {
        name: 'duotec',
        short_name: 'duotec',
        description: 'Building tommorow’s sensor and connectivity platforms',
      },
      routes: [{ key: 'articles', name: 'news' }],
    },
    */
    {
      code: 'de',
      name: 'German',
      nativeName: 'Deutsch',
      short: 'DE',
      manifest: {
        name: 'duotec',
        short_name: 'duotec',
        description: 'Zukunftsweisende Sensor- und Konnektivitätsplattformen',
      },
      routes: [{ key: 'articles', name: 'news' }],
    },
  ],
  defaultLanguage: 'de',
  fallbackLanguage: 'en',
};
